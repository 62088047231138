import { TypographyV3, type TypographyV3Props } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import type {
  MotionProps,
  MotionStyle,
  ValueAnimationTransition,
} from 'framer-motion';
import { useAnimate, useInView } from 'framer-motion';
import type { NumberFormatOptions } from 'next-intl';
import { useFormatter, useTranslations } from 'next-intl';
import {
  type ComponentPropsWithoutRef,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

import type { BasePriceProps } from '@/app/[locale]/_components/HomeRingsModules';
import Motion from '@/app/components/Motion';
import { RingStyleEnum } from '@/app/enums/RingStyleEnum';
import useCurrency from '@/app/hooks/useCurrency';
import type { RingPricingData } from '@/queries/ProductPricing';
import { useRingsLowestPricing } from '@/queries/ProductPricing';

interface AnimationProps {
  animate: MotionProps['animate'];
  initial: MotionProps['initial'];
  transition: ValueAnimationTransition;
  viewport?: MotionProps['viewport'];
}

export interface SectionHeadingProps {
  // Conditionally trigger intro animations on each line of text
  readonly animated?: boolean;
  readonly className?: string;
  // The MessageKey for the content of the heading.
  // The value for this should use <br> to separate the lines.
  readonly content: MessageKey;
  // Optional animation props for the italicized line of text
  readonly emAnimationProps?: Partial<AnimationProps>;
  // Optional props for em tag in heading
  readonly emProps?: ComponentPropsWithoutRef<'em'>;
  // Tailwind class list providing indentation values for second line
  readonly indentation?: string;
  // Conditionally break the regular and italicized text on to separate lines
  readonly multiline?: boolean;
  // Optional boolean to control when animation happens
  readonly shouldAnimate?: boolean;
  // Optional props Typography component that wraps the text
  readonly typographyProps?: Omit<TypographyV3Props, 'children'>;
  // Optional pixel width values of vertical spacing between lines at provided breakpoints
  readonly verticalSpacing?: string;
  // Optional animation props for the wrapper el
  readonly wrapperAnimationProps?: Partial<AnimationProps>;
}

interface EmProps extends ComponentPropsWithoutRef<'em'> {
  readonly animated: boolean;
  readonly animationProps: AnimationProps;
  readonly shouldAnimate: boolean;
}
export const useLowestPrice = ({ id }: BasePriceProps): string => {
  const currency = useCurrency();
  const format = useFormatter();
  const { data: lowestPricingData } = useRingsLowestPricing();
  const formatterOptions: NumberFormatOptions = {
    currency: currency.currencyCode,
    style: 'currency',
    trailingZeroDisplay: 'stripIfInteger',
  };

  if (lowestPricingData) {
    let lowestPrice: RingPricingData['lowestPrice'];
    if (id === 'gen3') {
      const heritageLowestPrice =
        lowestPricingData[RingStyleEnum.Heritage].lowestPrice;
      const horizonLowestPrice =
        lowestPricingData[RingStyleEnum.Horizon].lowestPrice;
      lowestPrice =
        heritageLowestPrice < horizonLowestPrice
          ? heritageLowestPrice
          : horizonLowestPrice;
    } else {
      lowestPrice = lowestPricingData[RingStyleEnum.OuraRing4].lowestPrice;
    }

    return format.number(lowestPrice, formatterOptions);
  }

  return '';
};

const DEFAULT_WRAPPER_ANIMATION_PROPS: AnimationProps = {
  animate: { opacity: 1, translateY: 0, filter: 'blur(0px)' },
  initial: { opacity: 0, translateY: 25, filter: 'blur(5px)' },
  transition: { delay: 0.25, duration: 1, type: 'tween' },
};

const DEFAULT_EM_ANIMATION_PROPS: AnimationProps = {
  animate: { opacity: 1, translateY: 0, filter: 'blur(0px)' },
  initial: { opacity: 0, translateY: 25, filter: 'blur(10px)' },
  transition: { duration: 1.25, type: 'tween' },
};

const Em = ({
  animated,
  animationProps,
  className,
  children,
  shouldAnimate,
  style,
}: EmProps): JSX.Element => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (shouldAnimate) {
      void animate(scope.current, animationProps.animate, {
        ...animationProps.transition,
      });
    }
  }, [
    animate,
    animationProps.animate,
    animationProps.transition,
    scope,
    shouldAnimate,
  ]);

  return (
    <Motion
      className={cx('font-serif italic', className)}
      el="em"
      data-cy="section_heading_em"
      initial={
        animated && animationProps.initial ? animationProps.initial : false
      }
      style={{ ...style } as MotionStyle}
      ref={scope}
    >
      {children}
    </Motion>
  );
};

export default function SectionHeading({
  animated = false,
  className,
  content,
  emAnimationProps = {},
  emProps = {},
  indentation,
  multiline = false,
  shouldAnimate,
  typographyProps = {
    Element: 'h1',
    variant: 'h1',
  },
  verticalSpacing,
  wrapperAnimationProps = {},
}: SectionHeadingProps): JSX.Element {
  const _wrapperAnimationProps: AnimationProps = {
    ...DEFAULT_WRAPPER_ANIMATION_PROPS,
    ...wrapperAnimationProps,
  };
  const _emAnimationProps: AnimationProps = {
    ...DEFAULT_EM_ANIMATION_PROPS,
    ...emAnimationProps,
  };
  const t = useTranslations();
  const [scope, animate] = useAnimate();
  const isInView = useInView(scope, { amount: 'all', once: true });
  const [isMultiline, setIsMultiline] = useState<boolean>(false);
  const _shouldAnimate = shouldAnimate ?? (animated && isInView);
  const { className: _emPropsClassName, ..._emProps } = emProps;
  const lowestPriceGen3 = useLowestPrice({ id: 'gen3' });

  const emClasses: Record<string, boolean> = useMemo(() => {
    const output: Record<string, boolean> = {
      block: isMultiline,
    };

    if (typeof indentation !== 'undefined') {
      output[indentation] = isMultiline;
    }

    if (typeof verticalSpacing !== 'undefined') {
      output[verticalSpacing] = isMultiline;
    }

    return output;
  }, [indentation, isMultiline, verticalSpacing]);

  // Use useEffect to prevent mismatch between SSR and CSR
  useEffect(() => {
    setIsMultiline(multiline);
  }, [multiline]);

  useEffect(() => {
    if (_shouldAnimate) {
      void animate(scope.current, _wrapperAnimationProps.animate, {
        ..._wrapperAnimationProps.transition,
      });
    }
  }, [
    _shouldAnimate,
    _wrapperAnimationProps.animate,
    _wrapperAnimationProps.transition,
    animate,
    scope,
  ]);

  return (
    <Motion
      className={className}
      initial={
        animated && _wrapperAnimationProps.initial
          ? _wrapperAnimationProps.initial
          : false
      }
      data-cy="section_heading"
      ref={scope}
    >
      <TypographyV3 {...typographyProps} data-cy="section_heading_text">
        {t.rich(content, {
          em: function em(chunks: ReactNode) {
            return (
              <Em
                {..._emProps}
                animated={animated}
                animationProps={_emAnimationProps}
                className={cx(emClasses, _emPropsClassName)}
                shouldAnimate={_shouldAnimate}
              >
                {chunks}
              </Em>
            );
          },
          sup: (chunks) => (
            <sup
              className="relative -top-4 text-base font-bold md:-top-8 lg:-top-10"
              data-cy="section_heading_sup"
            >
              {chunks}
            </sup>
          ),
          emprice: (chunks) => (
            <Em
              {..._emProps}
              animated={animated}
              animationProps={_emAnimationProps}
              className={cx(emClasses, _emPropsClassName)}
              shouldAnimate={_shouldAnimate}
              data-cy="section_heading_emprice"
            >
              {/* TODO: add function that pulls base price */}
              {chunks} {lowestPriceGen3}
            </Em>
          ),
        })}
      </TypographyV3>
    </Motion>
  );
}
