import {
  type GradientBGVarProps,
  GridItem,
  LayoutGrid,
  RadialGradientBG,
  type RadialGradientBGProps,
} from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

import Motion from '@/app/components/Motion';
import type { SectionHeadingProps } from '@/app/components/SectionHeading';

import LandingPageHeroBackground, {
  type LandingPageHeroBackgroundProps,
} from './LandingPageHeroBackground';
import LandingPageHeroCta, {
  type LandingPageHeroCtaProps,
} from './LandingPageHeroCta';
import LandingPageHeroDescription from './LandingPageHeroDescription';
import type { LandingPageHeroEyebrowProps } from './LandingPageHeroEyebrow';
import LandingPageHeroEyebrow from './LandingPageHeroEyebrow';
import LandingPageHeroImages, {
  type LandingPageHeroImagesProps,
} from './LandingPageHeroImages';
import LandingPageHeroTitle from './LandingPageHeroTitle';
import type { IconTagProps } from './LandingPageIconTag';
import LandingPageHeroIconTag from './LandingPageIconTag';

export interface LandingPageHeroProps
  extends ComponentPropsWithoutRef<'section'> {
  readonly backgroundImage?: LandingPageHeroBackgroundProps['backgroundImage'];
  readonly backgroundOverlay?: ReactNode;
  readonly backgroundProps: {
    color: GradientBGVarProps['background'];
    gradients: RadialGradientBGProps['gradients'];
  };
  readonly bottomIcon?: ReactNode;
  readonly copy: MessageKey;
  readonly copyProps?: ComponentPropsWithoutRef<'p'>;
  readonly cta?: LandingPageHeroCtaProps;
  readonly customEyebrow?: ReactNode;
  readonly eyebrow?: MessageKey;
  readonly eyebrowProps?: Omit<LandingPageHeroEyebrowProps, 'content'>;
  readonly images?: LandingPageHeroImagesProps['images'];
  readonly iconTagProps?: IconTagProps;
  readonly layoutGridClassName?: string;
  readonly textColor: string;
  readonly title: MessageKey;
  readonly titleProps?: Pick<
    SectionHeadingProps,
    'emProps' | 'indentation' | 'typographyProps'
  >;
}

const LandingPageHero = ({
  backgroundImage,
  backgroundOverlay,
  backgroundProps,
  bottomIcon,
  copy,
  copyProps,
  cta,
  customEyebrow,
  eyebrow,
  eyebrowProps,
  images,
  iconTagProps,
  layoutGridClassName,
  textColor,
  title,
  titleProps,
  ...props
}: LandingPageHeroProps): JSX.Element => {
  const hasBackgroundImage = typeof backgroundImage !== 'undefined';
  const hasEyebrow = typeof eyebrow !== 'undefined';

  return (
    <section
      className={cx(
        'relative lg:flex lg:h-auto lg:min-h-svh lg:flex-col lg:px-5 lg:pb-4',
        {
          'max-lg:z-30': cta?.fixed?.sm,
          'lg:z-30': cta?.fixed?.lg,
          'h-svh': hasBackgroundImage,
          'min-h-[50vw]': !hasBackgroundImage,
        },
      )}
      data-cy="landing-page-hero"
      {...props}
    >
      <RadialGradientBG
        background={backgroundProps.color}
        className={cx(
          'size-full overflow-hidden lg:flex lg:grow lg:flex-col lg:rounded-b-xl',
          textColor,
        )}
        gradients={backgroundProps.gradients}
      >
        {hasBackgroundImage && (
          <LandingPageHeroBackground
            backgroundImage={backgroundImage}
            backgroundOverlay={backgroundOverlay}
          />
        )}
        <LayoutGrid
          className={cx(
            'relative size-full pt-24 pb-16 lg:grow',
            {
              'lg:content-between': !hasBackgroundImage,
            },
            layoutGridClassName,
          )}
          data-cy="landing-page-hero-grid"
          rowGap={0}
        >
          <GridItem
            className={cx({ 'lg:mt-auto lg:self-center': hasBackgroundImage })}
            data-cy="landing-page-hero-title-column"
            colEnd={{ sm: 'main', lg: 14, xl: 12 }}
            colStart={{ sm: 'main' }}
            rowEnd={{ sm: 2 }}
            rowStart={{ sm: 1 }}
          >
            <div
              className={cx('my-5 flex flex-col lg:pt-[5vw]', {
                'items-center lg:items-start lg:pb-[8vw]': hasBackgroundImage,
                'gap-y-4 lg:gap-y-6': iconTagProps,
              })}
              data-cy="landing-page-hero-title-wrapper"
            >
              {iconTagProps && (
                <LandingPageHeroIconTag
                  icon={iconTagProps.icon}
                  iconTagText={iconTagProps.iconTagText}
                />
              )}
              {hasEyebrow && (
                <LandingPageHeroEyebrow {...eyebrowProps} content={eyebrow} />
              )}
              {customEyebrow && (
                <Motion
                  initial={{ filter: 'blur(5px)', opacity: 0 }}
                  transition={{ duration: 1.5, type: 'tween', delay: 0.5 }}
                  viewport={{ once: true }}
                  whileInView={{ filter: 'blur(0px)', opacity: 1 }}
                >
                  {customEyebrow}
                </Motion>
              )}
              <LandingPageHeroTitle
                content={title}
                hasEyebrow={hasEyebrow}
                {...titleProps}
              />
            </div>
          </GridItem>
          <GridItem
            className={cx('lg:self-end', {
              'mb-12 lg:mb-0': !hasBackgroundImage,
            })}
            colEnd={{
              sm: 'main',
              md: hasBackgroundImage ? 22 : 'main',
              lg: 11,
              xl: 9,
            }}
            colStart={{
              sm: 'main',
              md: hasBackgroundImage ? 4 : 'main',
              lg: 'main',
            }}
            data-cy="landing-page-hero-content-column"
            rowEnd={{ sm: 3 }}
            rowStart={{ sm: 2 }}
          >
            <Motion
              className={cx('flex flex-col gap-6', {
                'h-full items-center justify-end lg:h-auto lg:items-start lg:justify-start':
                  hasBackgroundImage,
                'pb-24': cta?.fixed?.sm,
                'lg:pb-20': cta?.fixed?.lg && !cta.hidden?.lg,
                'lg:pb-0': !cta?.fixed?.lg || cta.hidden?.lg,
              })}
              data-cy="landing-page-hero-description-wrapper"
              initial={{ opacity: 0 }}
              transition={{ duration: 1, type: 'tween', delay: 0.75 }}
              viewport={{ once: true }}
              whileInView={{ opacity: 1 }}
            >
              <LandingPageHeroDescription
                {...copyProps}
                content={copy}
                hasBackgroundImage={hasBackgroundImage}
              />
              {cta && <LandingPageHeroCta {...cta} />}
              {bottomIcon}
            </Motion>
          </GridItem>
          {images && <LandingPageHeroImages images={images} />}
        </LayoutGrid>
      </RadialGradientBG>
    </section>
  );
};

export default LandingPageHero;
