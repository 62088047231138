import type { ReactNode } from 'react';

import type { ArtDirectionImageProps } from '@/app/components/Image/ArtDirectionImage';
import ArtDirectionImage from '@/app/components/Image/ArtDirectionImage';
import Motion from '@/app/components/Motion';

export interface LandingPageHeroBackgroundProps {
  readonly backgroundImage: ArtDirectionImageProps;
  readonly backgroundOverlay?: ReactNode;
}

const LandingPageHeroBackground = ({
  backgroundImage,
  backgroundOverlay,
}: LandingPageHeroBackgroundProps): JSX.Element => {
  return (
    <Motion
      animate={{ opacity: 1, scale: 1 }}
      className="absolute top-0 left-0 size-full"
      data-cy="landing-page-hero-bg-image"
      initial={{ opacity: 0, scale: 1.1 }}
      transition={{ duration: 1.5, type: 'tween' }}
    >
      <ArtDirectionImage
        {...backgroundImage}
        className="object-cover"
        fill
        priority
      />
      {backgroundOverlay && (
        <div
          className="pointer-events-none absolute size-full"
          data-cy="landing-page-hero-bg-overlay"
        >
          {backgroundOverlay}
        </div>
      )}
    </Motion>
  );
};

export default LandingPageHeroBackground;
